<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Basic Information Dialog-->
    <profile-edit :profile="profile" />

    <!--Payment Profile Form-->
    <v-dialog v-model="paymentProfileDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Payment Method
          <v-spacer />
          <v-btn text icon color="white" @click="paymentProfileDialog = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="paymentProfile"
            v-model="paymentProfileValid"
            lazy-validation
          >
            <v-container fluid grid-list-sm>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="paymentProfile.d_nickname"
                    label="Card Nickname"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.paymentProfile.d_nickname"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="paymentProfile.d_company"
                    label="Company Name or Name On Card"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :disabled="paymentProfile.mode === 2"
                    :rules="rules.paymentProfile.d_company"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex v-if="paymentProfile.mode === 1" xs12 sm11>
                  <v-text-field
                    v-model="paymentProfile.d_ccCardNumber"
                    label="Card Number"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :rules="rules.paymentProfile.d_ccCardNumber"
                  />
                </v-flex>
                <v-flex v-if="paymentProfile.mode === 1" xs12 sm1>
                  <v-icon x-large>
                    {{ creditCardType(paymentProfile.d_ccCardNumber, 1, 0) }}
                  </v-icon>
                </v-flex>
                <v-flex v-if="paymentProfile.mode === 2" xs12 sm3>
                  <v-text-field
                    label="Type"
                    placeholder=" "
                    persistent-placeholder
                    :value="
                      creditCardType(
                        '',
                        3,
                        paymentProfile.fk_paymentMethodID_Internal
                      )
                    "
                    required
                    disabled
                  />
                </v-flex>
                <v-flex v-if="paymentProfile.mode === 2" xs12 sm3>
                  <v-text-field
                    v-model="paymentProfile.d_ccLast4"
                    label="Last 4"
                    placeholder=" "
                    persistent-placeholder
                    required
                    disabled
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    v-model="paymentProfile.d_ccExpDateMonth"
                    label="Expiration Month"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :disabled="paymentProfile.mode === 2"
                    :rules="rules.paymentProfile.d_ccExpDateYear"
                    hint="MM"
                  />
                </v-flex>
                <v-flex xs12 sm3>
                  <v-text-field
                    v-model="paymentProfile.d_ccExpDateYear"
                    label="Expiration Year"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :disabled="paymentProfile.mode === 2"
                    :rules="rules.paymentProfile.d_ccExpDateMonth"
                    hint="YYYY"
                  />
                </v-flex>
                <v-flex v-if="paymentProfile.mode === 1" xs12 sm2>
                  <v-text-field
                    v-model="paymentProfile.d_ccCVV"
                    label="CVV"
                    placeholder=" "
                    persistent-placeholder
                    required
                    :disabled="paymentProfile.mode === 2"
                    :rules="rules.paymentProfile.d_ccCVV"
                  />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="paymentProfile.f_default"
                    label="Default"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-if="paymentProfile.mode !== 1">
                <v-flex xs12 sm3>
                  <v-switch
                    v-model="paymentProfile.f_status"
                    label="Status"
                    color="green lighten-1"
                  />
                </v-flex>
              </v-layout>
              <v-layout v-if="paymentProfile === 1">
                <v-flex xs12 class="pt-4">
                  <div class="red--text">
                    Credit card numbers are never stored in the system. A secure
                    token representing the credit card number will be obtained
                    from the payment processor and used to process payments.
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded block color="primary" @click="savePaymentProfile()">
            <v-icon small left>
              fal fa-arrow-alt-down </v-icon
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat color="accent">
            <v-card-text class="pa-0">
              <v-container fluid grid-list-lg class="pa-0">
                <!--Basic Information & Payment Methods-->
                <v-layout row wrap>
                  <!--Basic Information-->
                  <v-flex xs12 md9 class="pt-0">
                    <v-toolbar flat dense dark color="secondary">
                      <v-icon>fal fa-info-circle</v-icon>
                      <v-toolbar-title class="pl-2">
                        Basic Information
                      </v-toolbar-title>
                      <v-spacer />
                      <v-btn
                        outlined
                        rounded
                        @click="
                          editProfile(
                            1,
                            customersStore.customer,
                            usersStore.user
                          )
                        "
                      >
                        <v-icon small left>
                          fal fa-pencil </v-icon
                        >Edit
                      </v-btn>
                    </v-toolbar>
                    <v-card flat>
                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12 md6>
                            <v-layout row wrap>
                              <v-flex xs6 class="py-0">
                                <v-text-field
                                  v-model="usersStore.user.d_nameFirst"
                                  label="First Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>
                              <v-flex xs6 class="py-0">
                                <v-text-field
                                  v-model="usersStore.user.d_nameLast"
                                  label="Last Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <v-text-field
                                  v-model="usersStore.user.email"
                                  label="Email Address"
                                  placeholder=" "
                                  persistent-placeholder
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>
                              <v-flex xs12 class="py-0">
                                <v-text-field
                                  v-model="
                                    customersStore.customer.d_companyName
                                  "
                                  label="Company Name"
                                  placeholder=" "
                                  persistent-placeholder
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>
                              <v-flex xs6 class="py-0">
                                <v-text-field
                                  v-model="customersStore.customer.d_terms"
                                  label="Terms"
                                  placeholder=" "
                                  persistent-placeholder
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>
                              <v-flex xs6 class="py-0">
                                <v-text-field
                                  v-model="
                                    customersStore.customer.d_taxableStatus
                                  "
                                  label="Tax Status"
                                  flat
                                  readonly
                                  loading="false"
                                />
                              </v-flex>

                              <!--COMPANY ADDRESS-->
                              <v-flex xs12 class="pb-0">
                                <div class="caption">
                                  Company Address
                                </div>
                              </v-flex>
                              <v-flex xs12 class="pt-0">
                                <div class="black--text subtitle-1">
                                  <div
                                    v-if="customersStore.customer.d_companyName"
                                  >
                                    {{ customersStore.customer.d_companyName
                                    }}<br />
                                  </div>
                                  <div v-if="customersStore.customer.d_street1">
                                    {{ customersStore.customer.d_street1
                                    }}<br />
                                  </div>
                                  <div v-if="customersStore.customer.d_street2">
                                    {{ customersStore.customer.d_street2
                                    }}<br />
                                  </div>
                                  <span v-if="customersStore.customer.d_city"
                                    >{{ customersStore.customer.d_city }},
                                  </span>
                                  <span v-if="customersStore.customer.d_state"
                                    >{{ customersStore.customer.d_state }}
                                  </span>
                                  <span v-if="customersStore.customer.d_zip">{{
                                    customersStore.customer.d_zip
                                  }}</span>
                                </div>
                              </v-flex>

                              <!--BILLING ADDRESS-->
                              <v-flex xs12 class="pb-0">
                                <div class="caption">
                                  Billing Address
                                </div>
                              </v-flex>
                              <v-flex xs12 class="pt-0">
                                <div class="black--text subtitle-1">
                                  <div
                                    v-if="
                                      customersStore.customer
                                        .d_billingCompanyName
                                    "
                                  >
                                    {{
                                      customersStore.customer
                                        .d_billingCompanyName
                                    }}<br />
                                  </div>
                                  <div
                                    v-if="
                                      customersStore.customer.d_billingStreet1
                                    "
                                  >
                                    {{ customersStore.customer.d_billingStreet1
                                    }}<br />
                                  </div>
                                  <div
                                    v-if="
                                      customersStore.customer.d_billingStreet2
                                    "
                                  >
                                    {{ customersStore.customer.d_billingStreet2
                                    }}<br />
                                  </div>
                                  <span
                                    v-if="customersStore.customer.d_billingCity"
                                    >{{
                                      customersStore.customer.d_billingCity
                                    }},
                                  </span>
                                  <span
                                    v-if="
                                      customersStore.customer.d_billingState
                                    "
                                    >{{
                                      customersStore.customer.d_billingState
                                    }}
                                  </span>
                                  <span
                                    v-if="customersStore.customer.d_billingZip"
                                    >{{
                                      customersStore.customer.d_billingZip
                                    }}</span
                                  >
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <!--Contact Methods-->
                          <v-flex xs12 md6 class="pt-0">
                            <div class="caption">
                              Contact Information
                            </div>
                            <v-list>
                              <template
                                v-for="(item, i) in usersStore.user
                                  .j_contactMethods"
                              >
                                <v-list-item :key="i">
                                  <v-list-item-avatar>
                                    <v-icon
                                      v-if="item.f_type === 1"
                                      medium
                                      color="primary"
                                    >
                                      fal fa-phone
                                    </v-icon>
                                    <v-icon
                                      v-if="item.f_type === 2"
                                      medium
                                      color="primary"
                                    >
                                      fal fa-mobile
                                    </v-icon>
                                    <v-icon
                                      v-if="item.f_type === 3"
                                      medium
                                      color="primary"
                                    >
                                      fal fa-at
                                    </v-icon>
                                    <v-icon
                                      v-if="item.f_type === 4"
                                      medium
                                      color="primary"
                                    >
                                      fal fa-fax
                                    </v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title class="text--primary">
                                      {{ item.d_contactDetail }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-flex>

                  <!--Right Side-->
                  <v-flex xs12 md3 class="pt-0">
                    <v-layout row wrap>
                      <!--Payment Methods-->
                      <v-flex xs12>
                        <v-toolbar flat dense color="secondary" dark>
                          <v-icon>fal fa-credit-card</v-icon>
                          <v-toolbar-title class="pl-2">
                            Cards
                          </v-toolbar-title>
                          <v-spacer />
                          <v-btn
                            v-if="
                              usersStore.authUser.f_managePaymentMethods === 1
                            "
                            rounded
                            color="green lighten-1"
                            @click="newPaymentProfile()"
                          >
                            <v-icon small left>
                              fal fa-plus </v-icon
                            >New
                          </v-btn>
                        </v-toolbar>
                        <v-list two-line class="pt-0">
                          <template
                            v-for="(item,
                            i) in paymentProfilesStore.paymentProfiles"
                          >
                            <v-list-item
                              :key="i"
                              @click="editPaymentProfile(item)"
                            >
                              <v-list-item-icon>
                                <v-icon tile color="primary" large>
                                  {{ item.payment_method.d_icon }}
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-if="
                                    usersStore.authUser.customers.length > 0
                                  "
                                  class="font-weight-bold"
                                >
                                  {{ item.customer.d_companyName }}
                                </v-list-item-title>

                                <v-list-item-title class="text--primary">
                                  {{ item.d_nickname }} -
                                  {{ item.d_ccLast4 }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  >Expires:
                                  {{ item.d_ccExpDate }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-list-item-action-text
                                  :class="
                                    'text-xs-right subtitle-1 ' +
                                      [
                                        item.f_status === 1
                                          ? 'green--text lighten-1--text'
                                          : 'error--text'
                                      ]
                                  "
                                >
                                  {{ translateStatus(item.f_status) }}
                                </v-list-item-action-text>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                          </template>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!--Users-->
                <v-layout
                  v-if="usersStore.authUser.f_manageUsers === 1"
                  row
                  wrap
                >
                  <v-flex xs12 class="pt-0">
                    <v-toolbar flat dense color="secondary" dark>
                      <v-icon>fal fa-lock</v-icon>
                      <v-toolbar-title class="pl-2">
                        Users
                      </v-toolbar-title>
                      <v-spacer />
                      <v-btn
                        rounded
                        color="green lighten-1"
                        @click="editProfile(3, customersStore.customer)"
                      >
                        <v-icon small left>
                          fal fa-plus </v-icon
                        >New
                      </v-btn>
                    </v-toolbar>
                    <v-list two-line class="pt-0">
                      <template v-for="(item, i) in usersStore.users">
                        <v-list-item
                          v-if="usersStore.authUser.id !== item.id"
                          :key="i"
                          @click="editProfile(2, customersStore.customer, item)"
                        >
                          <v-list-item-avatar :color="item.d_avatarColor">
                            <span class="white--text headline">{{
                              avatar(item.name)
                            }}</span>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-if="usersStore.authUser.customers.length > 0"
                              class="font-weight-bold"
                            >
                              {{ item.customer.d_companyName }}
                            </v-list-item-title>

                            <v-list-item-title class="text--primary">
                              {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.email
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            {{ translateStatus(item.f_access) }}
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { authorizeHeader } from "@/env";
import { mixin } from "@/mixins/mixin.js";
import ProfileEdit from "@/components/ProfileEdit";

export default {
  name: "Profile",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      customersStore: state => state.customersStore,
      paymentProfilesStore: state => state.paymentProfilesStore,
      paymentMethodsStore: state => state.paymentMethodsStore,
      navigationStore: state => state.navigationStore,
      alertsStore: state => state.alertsStore
    })
  },
  components: {
    ProfileEdit
  },
  data() {
    return {
      progress: false,
      paymentProfileDialog: false,
      paymentProfileValid: true,
      userValid: true,
      search: "",
      editFlag: "",
      profile: {
        open: "",
        mode: "",
        valid: true,
        user: {
          id: "",
          fk_customerID: "",
          d_nameFirst: "",
          d_nameLast: "",
          d_avatarColor: "",
          email: "",
          password: "",
          f_access: "",
          f_primary: "",
          f_manageUsers: "",
          f_managePaymentMethods: "",
          f_deleteRecords: "",
          navigation: [],
          j_contactMethods: []
        },
        customer: {
          id: "",
          d_companyName: "",
          d_street1: "",
          d_street2: "",
          d_city: "",
          d_state: "",
          d_zip: "",
          d_county: "",
          d_country: "",
          f_billingSameAsCompany: "",
          d_billingCompanyName: "",
          d_billingStreet1: "",
          d_billingStreet2: "",
          d_billingCity: "",
          d_billingState: "",
          d_billingZip: "",
          d_billingCounty: "",
          d_billingCountry: "",
          d_taxCertificateYear: "",
          d_taxCertificateNumber: "",
          d_taxCertificateFile: ""
        },
        navigation: [],
        contactMethods: []
      },
      paymentProfile: {
        mode: "",
        id: "",
        customerProfileId: "",
        fk_customerID: "",
        d_nickname: "",
        fk_paymentMethodID_Internal: "",
        d_ccCVV: "",
        d_ccExpDateYear: "",
        d_ccExpDateMonth: "",
        d_ccCardNumber: "",
        d_company: "",
        d_ccLast4: "",
        d_profileID: "",
        f_default: "",
        f_status: ""
      },
      paymentMethods: [
        {
          fk_internalID: 6,
          d_name: "American Express"
        },
        {
          fk_internalID: 7,
          d_name: "Discover"
        },
        {
          fk_internalID: 8,
          d_name: "MasterCard"
        },
        {
          fk_internalID: 9,
          d_name: "Visa"
        }
      ],
      rules: {
        paymentProfile: {
          d_nickname: [v => !!v || "required"],
          fk_paymentMethodID_Internal: [v => !!v || "required"],
          d_company: [v => !!v || "required"],
          d_ccCardNumber: [v => !!v || "required"],
          d_ccExpDateMonth: [v => !!v || "required"],
          d_ccExpDateYear: [v => !!v || "required"],
          d_ccCVV: [v => !!v || "required"]
        }
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.progress = true;
      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_userID: user.id
      };

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        /* Fetch Customer Data */
        await this.$store.dispatch("usersStore/read", user.id);
        await this.$store.dispatch("customersStore/read", user.fk_customerID);
        await this.$store.dispatch(
          "paymentProfilesStore/fetchByCustomer",
          data
        );
        await this.$store.dispatch("usersStore/fetchByCustomer", data);
        this.progress = false;
      }
    },

    runSearch() {
      if (this.search) {
        const searchQuery = {
          search: this.search
        };
        this.$store.dispatch("designsStore/query", searchQuery);
      } else {
        this.$store.dispatch("designsStore/fetch");
      }
    },

    clearSearch() {
      this.search = "";
      this.initialize();
    },

    async editProfile(mode, customer, user) {
      this.profile.user.password = "";
      this.profile.navigation = [];
      this.profile.contactMethods = [];
      this.profile.user.navigation = [];
      this.profile.user.j_contactMethods = [];
      this.files = [];
      this.profile.user = {};

      /* If editing user from list or creating new user */
      if (mode === 2 || mode === 3) {
        await this.$store.dispatch("navigationStore/fetch");
      }

      /* If editing authuser or editing user in the list */
      if (mode === 1 || mode === 2) {
        /* Build navigation selections */
        if (user.navigation.length) {
          for (let i = 0; i < user.navigation.length; i++) {
            this.profile.navigation.push(user.navigation[i].id);
          }
        }

        /* Build contact methods */
        if (user.j_contactMethods) {
          for (let i = 0; i < user.j_contactMethods.length; i++) {
            const data = {
              id: user.j_contactMethods[i].id,
              d_contactDetail: user.j_contactMethods[i].d_contactDetail,
              f_accounting: user.j_contactMethods[i].f_accounting,
              f_designApproval: user.j_contactMethods[i].f_designApproval,
              f_shipConfirmation: user.j_contactMethods[i].f_shipConfirmation,
              f_type: user.j_contactMethods[i].f_type
            };
            this.profile.contactMethods.push(data);
          }
        }
        this.profile.user.id = user.id;
        this.profile.user.fk_customerID = customer.id;
        this.profile.user.d_nameFirst = user.d_nameFirst;
        this.profile.user.d_nameLast = user.d_nameLast;
        this.profile.user.d_avatarColor = user.d_avatarColor;
        this.profile.user.email = user.email;
        this.profile.user.f_access = user.f_access;
        this.profile.user.f_primary = user.f_primary;
        this.profile.user.f_manageUsers = user.f_manageUsers;
        this.profile.user.f_managePaymentMethods = user.f_managePaymentMethods;
        this.profile.user.f_deleteRecords = user.f_deleteRecords;

        this.profile.customer.id = customer.id;
        this.profile.customer.d_companyName = customer.d_companyName;
        this.profile.customer.d_street1 = customer.d_street1;
        this.profile.customer.d_street2 = customer.d_street2;
        this.profile.customer.d_city = customer.d_city;
        this.profile.customer.d_state = customer.d_state;
        this.profile.customer.d_zip = customer.d_zip;
        this.profile.customer.d_county = customer.d_county;
        this.profile.customer.d_country = customer.d_country;

        this.profile.customer.f_billingSameAsCompany =
          customer.f_billingSameAsCompany;
        this.profile.customer.d_billingCompanyName = customer.d_companyName;
        this.profile.customer.d_billingStreet1 = customer.d_street1;
        this.profile.customer.d_billingSreet2 = customer.d_street2;
        this.profile.customer.d_billingCity = customer.d_city;
        this.profile.customer.d_billingState = customer.d_state;
        this.profile.customer.d_billingZip = customer.d_zip;
        this.profile.customer.d_billingCounty = customer.d_county;
        this.profile.customer.d_billingCountry = customer.d_country;

        this.profile.customer.d_taxCertificateYear =
          customer.d_taxCertificateYear;
        this.profile.customer.d_taxCertificateNumber =
          customer.d_taxCertificateNumber;
        this.profile.customer.d_taxCertificateFile =
          customer.d_taxCertificateFile;
      } else {
      /* New User */
        this.profile.user.fk_customerID = customer.id;
        this.profile.user.f_access = 1;
        this.profile.navigation = [1, 5, 6, 7, 8, 10, 11];
      }
      this.profile.mode = mode;
      this.profile.open = true;
    },

    async newPaymentProfile() {
      this.paymentProfileValid = true;
      this.paymentProfile.mode = 1;
      this.paymentProfile.customerProfileId = "";
      this.paymentProfile.fk_customerID = "";
      this.paymentProfile.fk_paymentMethodID_Internal = "";
      this.paymentProfile.d_nickname = "";
      this.paymentProfile.d_company = "";
      this.paymentProfile.d_ccCVV = "";
      this.paymentProfile.d_ccExpDate = "";
      this.paymentProfile.d_ccExpDateMonth = "";
      this.paymentProfile.d_ccExpDateYear = "";
      this.paymentProfile.d_ccCardNumber = "";
      this.paymentProfile.d_ccLast4 = "";
      this.paymentProfile.d_profileID = "";
      this.paymentProfile.f_default = "";
      this.paymentProfile.f_status = 1;

      // await this.$store.dispatch('paymentMethodsStore/fetch')

      this.paymentProfileDialog = true;
      this.$refs.paymentProfile.resetValidation();
    },

    async editPaymentProfile(item) {
      const [month, year] = item.d_ccExpDate.split("-");
      this.paymentProfile.id = item.id;
      this.paymentProfile.mode = 2;
      this.paymentProfile.d_nickname = item.d_nickname;
      this.paymentProfile.fk_paymentMethodID_Internal =
        item.fk_paymentMethodID_Internal;
      this.paymentProfile.d_company = item.d_company;
      this.paymentProfile.d_ccLast4 = item.d_ccLast4;
      this.paymentProfile.d_ccExpDateMonth = month;
      this.paymentProfile.d_ccExpDateYear = year;
      this.paymentProfile.d_ccCVV = item.d_ccCVV;
      this.paymentProfile.f_default = item.f_default;
      this.paymentProfile.f_status = item.f_status;

      // await this.$store.dispatch('paymentMethodsStore/fetch')

      this.paymentProfileDialog = true;
    },

    async createCustomerProfileRequest(internalID) {
      const data = {
        createCustomerProfileRequest: {
          merchantAuthentication: {
            name: process.env.VUE_APP_AUTHORIZE_MERCHANTID,
            transactionKey: process.env.VUE_APP_AUTHORIZE_TRANSACTIONKEY
          },
          profile: {
            merchantCustomerId: internalID
          }
        }
      };
      try {
        return await axios.post(process.env.VUE_APP_AUTHORIZE_URL, data, {
          headers: authorizeHeader()
        });
      } catch (error) {
        const errorResponse = {
          data: {
            messages: {
              resultCode: "Error",
              message: error
            }
          }
        };
        return errorResponse;
      }
    },

    async createCustomerPaymentProfileRequest(paymentProfile) {
      const data = {
        createCustomerPaymentProfileRequest: {
          merchantAuthentication: {
            name: process.env.VUE_APP_AUTHORIZE_MERCHANTID,
            transactionKey: process.env.VUE_APP_AUTHORIZE_TRANSACTIONKEY
          },
          customerProfileId: paymentProfile.customerProfileId,
          paymentProfile: {
            payment: {
              creditCard: {
                cardNumber: paymentProfile.d_ccCardNumber,
                expirationDate: `${paymentProfile.d_ccExpDateYear}-${paymentProfile.d_ccExpDateMonth}`,
                cardCode: paymentProfile.d_ccCVV
              }
            }
          }
        }
      };
      try {
        return await axios.post(process.env.VUE_APP_AUTHORIZE_URL, data, {
          headers: authorizeHeader()
        });
      } catch (error) {
        const errorResponse = {
          data: {
            messages: {
              resultCode: "Error",
              message: error
            }
          }
        };
        return errorResponse;
      }
    },

    async savePaymentProfile() {
      if (this.$refs.paymentProfile.validate()) {
        this.paymentProfile.fk_paymentMethodID_Internal = this.creditCardType(
          this.paymentProfile.d_ccCardNumber,
          2,
          0
        );

        /* If new payment profile */
        if (this.paymentProfile.mode === 1) {
          const customer = this.$store.getters["customersStore/getCustomer"];

          /* If customer profile is empty */
          if (!customer.d_paymentCustomerProfileID) {
            const customerProfile = await this.createCustomerProfileRequest(
              customer.id_Internal
            );

            /* Test response */
            const check = this.checkAuthorizeRequest(customerProfile);
            /* If response is successful */
            if (check === 1) {
              const profileData = {
                id: customer.id,
                d_paymentCustomerProfileID:
                  customerProfile.data.customerProfileId
              };
              await this.$store.dispatch(
                "customersStore/updateAuthorizeProfile",
                profileData
              );

              this.paymentProfile.customerProfileId =
                customerProfile.data.customerProfileId;
            } else {
              /* ERROR */
              this.openSnackbar(1, check);
            }
          } else {
            this.paymentProfile.customerProfileId =
              customer.d_paymentCustomerProfileID;
          }

          /* Submit credit card to obtain token */
          const paymentProfile = await this.createCustomerPaymentProfileRequest(
            this.paymentProfile
          );

          /* Test response */
          const check2 = this.checkAuthorizeRequest(paymentProfile);
          /* If response is successful */
          if (check2 === 1) {
            this.paymentProfile.fk_customerID = customer.id;
            this.paymentProfile.d_profileID =
              paymentProfile.data.customerPaymentProfileId;
            this.paymentProfile.d_ccLast4 = this.paymentProfile.d_ccCardNumber.substr(
              this.paymentProfile.d_ccCardNumber.length - 4
            );
            this.paymentProfile.d_ccCardNumber = "";
            this.paymentProfile.d_ccCVV = "";
            this.paymentProfile.d_ccExpDate = `${this.paymentProfile.d_ccExpDateMonth}-${this.paymentProfile.d_ccExpDateYear}`;

            await this.$store.dispatch(
              "paymentProfilesStore/create",
              this.paymentProfile
            );
            this.paymentProfile.customerProfileId = "";
            this.paymentProfile.fk_customerID = "";
            this.paymentProfile.fk_paymentMethodID_Internal = "";
            this.paymentProfile.d_nickname = "";
            this.paymentProfile.d_company = "";
            this.paymentProfile.d_ccCVV = "";
            this.paymentProfile.d_ccExpDate = "";
            this.paymentProfile.d_ccExpDateMonth = "";
            this.paymentProfile.d_ccExpDateYear = "";
            this.paymentProfile.d_ccCardNumber = "";
            this.paymentProfile.d_ccLast4 = "";
            this.paymentProfile.d_profileID = "";
            this.paymentProfile.f_default = "";
            this.paymentProfileDialog = false;
          } else {
            /* ERROR */
            this.openSnackbar(1, check2);
          }
        } else {
          const data = {
            id: this.paymentProfile.id,
            d_nickname: this.paymentProfile.d_nickname,
            f_status: this.paymentProfile.f_status,
            f_default: this.paymentProfile.f_default
          };
          await this.$store.dispatch("paymentProfilesStore/update", data);
          this.paymentProfileDialog = false;
        }
        this.paymentProfileDialog = false;
      }
    },

    editUser(user) {
      this.editFlag = 1;
      this.user.id = user.id;
      this.user.d_nameFirst = user.d_nameFirst;
      this.user.d_nameLast = user.d_nameLast;
      this.user.email = user.email;
      this.user.password = user.password;
      this.user.f_access = user.f_access;
      this.userDialog = true;
    },

    newUser() {
      this.editFlag = "";
      this.user.f_access = 1;
      this.userDialog = true;
    },

    saveUser() {
      if (this.$refs.user.validate()) {
        if (this.editFlag === 1) {
          this.$store.dispatch("usersStore/update", this.user).then(() => {
            this.userDialog = false;
          });
        } else {
          const user = this.$store.getters["usersStore/getAuthUser"];

          this.profile.user.fk_customerID = user.fk_customerID;

          this.$store.dispatch("usersStore/create", this.user).then(() => {
            this.userDialog = false;
          });
        }
      }
    },

    creditCardType(cardNumber, mode, id) {
      let type;
      if (cardNumber) {
        type = Number(cardNumber.charAt(0));
      } else {
        type = 0;
      }
      /* American Express */
      if (type === 3 || id === 6) {
        if (mode === 1) {
          return "fab fa-cc-amex";
        }
        if (mode === 2) {
          return 6;
        }
        return "AMEX";
      }
      /* Visa */
      if (type === 4 || id === 9) {
        if (mode === 1) {
          return "fab fa-cc-visa";
        }
        if (mode === 2) {
          return 9;
        }
        return "Visa";
      }
      /* MasterCard */
      if (type === 5 || id === 8) {
        if (mode === 1) {
          return "fab fa-cc-mastercard";
        }
        if (mode === 2) {
          return 8;
        }
        return "MasterCard";
      }
      /* Discover */
      if (type === 6 || id === 7) {
        if (mode === 1) {
          return "fab fa-cc-discover";
        }
        if (mode === 2) {
          return 7;
        }
        return "Discover";
      }
      /* Other */

      if (mode === 1) {
        return "far fa-credit-card-front";
      }
      if (mode === 2) {
        return 13;
      }
      return "Other";
    },

    openSnackbar(code, message) {
      let data;
      if (code === 1) {
        data = {
          color: "error",
          text: message
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text: "Login error - Please contact support."
        };
      } else if (code === 3) {
        data = {
          color: "error",
          text: "Login error - email and password combination are incorrect."
        };
      } else if (code === 4) {
        data = {
          color: "green lighten-1",
          text: "Password reset email sent successfully."
        };
      } else if (code === 5) {
        data = {
          color: "error",
          text:
            "There was an error with the request. Please contact support if the issue continues."
        };
      }
      this.$store.commit("alertsStore/setAlert", data);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.rounded-card {
  border-radius: 8px;
}
button {
  outline: none;
}
</style>
