<template>
  <div>
    <!--File Upload Progress Bar-->
    <v-dialog v-model="upload" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          File Uploading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="profile.open" scrollable persistent max-width="800">
      <v-card class="rounded-card">
        <v-card-title class="secondary white--text headline font-weight-medium">
          <span v-if="profile.mode === 1">Basic Information</span>
          <span v-else>User Details</span>
          <v-spacer />
          <v-btn text icon color="white" @click="close()">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="profile" v-model="profile.valid" lazy-validation>
            <v-tabs grow show-arrows slider-color="secondary">
              <v-tab>User</v-tab>
              <v-tab
                v-if="usersStore.authUser.f_primary === 1 && profile.mode === 1"
              >
                Company Address
              </v-tab>
              <v-tab
                v-if="usersStore.authUser.f_primary === 1 && profile.mode === 1"
              >
                Billing Information
              </v-tab>
              <v-tab>Contact Information</v-tab>
              <v-tab v-if="usersStore.authUser.f_primary === 1">
                Settings
              </v-tab>
              <v-tab v-if="profile.mode !== 1">
                Sections
              </v-tab>

              <!--User Details-->
              <v-tab-item>
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="profile.user.d_nameFirst"
                            label="First Name"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules.user.d_nameFirst"
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="profile.user.d_nameLast"
                            label="Last Name"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules.user.d_nameLast"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.user.email"
                            label="Email"
                            placeholder=" "
                            persistent-placeholder
                            :rules="rules.user.email"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.user.password"
                            label="Password"
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-select
                            v-model="profile.user.d_avatarColor"
                            :items="avatarColors"
                            item-text="text"
                            item-value="value"
                            label="Avatar Color"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.user.d_avatarColor"
                          />
                        </v-flex>
                        <v-flex
                          v-if="profile.user.id !== usersStore.authUser.id"
                          xs12
                        >
                          <v-switch
                            v-model="profile.user.f_access"
                            label="Site Access"
                            color="green lighten-1"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--Company Address-->
              <v-tab-item
                v-if="usersStore.authUser.f_primary === 1 && profile.mode === 1"
              >
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_companyName"
                            label="Company Name"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_companyName"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_street1"
                            label="Address 1"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_street1"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_street2"
                            label="Address 2"
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>

                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="profile.customer.d_city"
                            label="City"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_city"
                          />
                        </v-flex>

                        <v-flex xs12 sm2>
                          <v-text-field
                            v-model="profile.customer.d_state"
                            label="State"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_state"
                          />
                        </v-flex>

                        <v-flex xs12 sm3>
                          <v-text-field
                            v-model="profile.customer.d_zip"
                            label="Postal Code"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_zip"
                          />
                        </v-flex>

                        <!--<v-flex xs12 sm10>
                        <v-text-field
                          label="County"
                          v-model="profile.customer.d_county">
                        </v-text-field>
                      </v-flex>-->

                        <v-flex xs12 sm1>
                          <v-text-field
                            v-model="profile.customer.d_country"
                            label="Country"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_country"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--Billing Information-->
              <v-tab-item
                v-if="usersStore.authUser.f_primary === 1 && profile.mode === 1"
              >
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-checkbox
                            v-model="profile.customer.f_billingSameAsCompany"
                            color="primary"
                            @click.native="sameAddress()"
                          >
                            <div slot="label">
                              Same as Company Address
                            </div>
                          </v-checkbox>
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_billingCompanyName"
                            label="Company Name"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_billingCompanyName"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_billingStreet1"
                            label="Address 1"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_billingStreet1"
                          />
                        </v-flex>

                        <v-flex xs12>
                          <v-text-field
                            v-model="profile.customer.d_billingStreet2"
                            label="Address 2"
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>

                        <v-flex xs12 sm6>
                          <v-text-field
                            v-model="profile.customer.d_billingCity"
                            label="City"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_billingCity"
                          />
                        </v-flex>

                        <v-flex xs12 sm2>
                          <v-text-field
                            v-model="profile.customer.d_billingState"
                            label="State"
                            required
                            :rules="rules.customer.d_billingState"
                          />
                        </v-flex>

                        <v-flex xs12 sm3>
                          <v-text-field
                            v-model="profile.customer.d_billingZip"
                            label="Postal Code"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_billingZip"
                          />
                        </v-flex>

                        <!--<v-flex xs12 sm10>
                        <v-text-field
                          label="County"
                          v-model="profile.customer.d_billingCounty">
                        </v-text-field>
                      </v-flex>-->

                        <v-flex xs12 sm1>
                          <v-text-field
                            v-model="profile.customer.d_billingCountry"
                            label="Country"
                            placeholder=" "
                            persistent-placeholder
                            required
                            :rules="rules.customer.d_billingCountry"
                          />
                        </v-flex>

                        <v-flex
                          v-if="
                            usersStore.authUser.f_primary === 1 &&
                              profile.mode === 1
                          "
                          xs12
                        >
                          <span class="subtitle-1 secondary--text"
                            >Sales Tax Details</span
                          >
                        </v-flex>

                        <v-flex
                          v-if="
                            usersStore.authUser.f_primary === 1 &&
                              profile.mode === 1
                          "
                          xs12
                          sm2
                        >
                          <v-text-field
                            v-model="profile.customer.d_taxCertificateYear"
                            label="Certificate Year"
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>

                        <v-flex
                          v-if="
                            usersStore.authUser.f_primary === 1 &&
                              profile.mode === 1
                          "
                          xs12
                          sm3
                        >
                          <v-text-field
                            v-model="profile.customer.d_taxCertificateNumber"
                            label="Certificate Number"
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>

                        <v-flex
                          v-if="
                            usersStore.authUser.f_primary === 1 &&
                              profile.mode === 1
                          "
                          xs12
                          sm7
                        >
                          <v-text-field
                            v-model="profile.customer.d_taxCertificateFile"
                            label="Current Certificate File"
                            readonly
                            placeholder=" "
                            persistent-placeholder
                          />
                        </v-flex>

                        <v-flex
                          v-if="
                            usersStore.authUser.f_primary === 1 &&
                              profile.mode === 1
                          "
                          xs12
                        >
                          <v-file-input
                            ref="file"
                            v-model="files"
                            clearable
                            clear-icon="fal fa-times-circle"
                            prepend-icon="fal fa-paperclip"
                            label="Attach New Tax Certificate File"
                            placeholder=" "
                            persistent-placeholder
                            required
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--Contact Information-->
              <v-tab-item>
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-spacer />
                        <v-btn
                          rounded
                          dark
                          color="green lighten-1"
                          class="mb-2"
                          @click="addContactMethod()"
                        >
                          <v-icon small left>
                            fal fa-plus </v-icon
                          >New
                        </v-btn>
                        <v-flex xs12>
                          <v-card
                            v-for="(item, index) in profile.contactMethods"
                            :key="index"
                            flat
                          >
                            <v-container fluid class="pa-0">
                              <v-layout row>
                                <v-flex xs12 md2>
                                  <v-select
                                    key="id"
                                    v-model="item.f_type"
                                    :items="contactMethodTypes"
                                    item-text="value"
                                    item-value="id"
                                    label="Type"
                                  />
                                </v-flex>

                                <v-flex xs12 md6>
                                  <v-text-field
                                    v-model="item.d_contactDetail"
                                    label="Detail"
                                    flat
                                    hide-details
                                  />
                                </v-flex>

                                <v-flex v-if="item.f_type === 3" xs12 md3>
                                  <v-menu
                                    offset-y
                                    open-on-hover
                                    :close-on-content-click="false"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        rounded
                                        outlined
                                        block
                                        color="secondary"
                                        v-on="on"
                                      >
                                        <v-icon small left>
                                          fal fa-envelope </v-icon
                                        >Notifications
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-switch
                                            v-model="item.f_accounting"
                                            color="green lighten-1"
                                          />
                                        </v-list-item-action>
                                        <v-list-item-title
                                          >Accounting</v-list-item-title
                                        >
                                      </v-list-item>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-switch
                                            v-model="item.f_designApproval"
                                            color="green lighten-1"
                                          />
                                        </v-list-item-action>
                                        <v-list-item-title
                                          >Design</v-list-item-title
                                        >
                                      </v-list-item>
                                      <v-list-item>
                                        <v-list-item-action>
                                          <v-switch
                                            v-model="item.f_shipConfirmation"
                                            color="green lighten-1"
                                          />
                                        </v-list-item-action>
                                        <v-list-item-title
                                          >Shipping</v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-flex>

                                <v-flex v-else xs12 md3 />

                                <v-flex xs12 md1>
                                  <v-btn
                                    text
                                    icon
                                    color="red"
                                    @click="removeContactMethod(index)"
                                  >
                                    <v-icon>fal fa-trash</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--Settings-->
              <v-tab-item v-if="usersStore.authUser.f_primary === 1">
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-flex d-flex xs12 class="py-0">
                          <v-switch
                            v-model="profile.user.f_primary"
                            :disabled="
                              usersStore.authUser.id === profile.user.id
                            "
                            label="Primary User"
                            color="green lighten-1"
                          />
                        </v-flex>
                        <v-flex d-flex xs12 class="py-0">
                          <v-switch
                            v-model="profile.user.f_manageUsers"
                            label="Manage Users"
                            color="green lighten-1"
                          />
                        </v-flex>
                        <v-flex d-flex xs12 class="py-0">
                          <v-switch
                            v-model="profile.user.f_managePaymentMethods"
                            label="Manage Payment Methods"
                            color="green lighten-1"
                          />
                        </v-flex>
                        <v-flex d-flex xs12 class="py-0">
                          <v-switch
                            v-model="profile.user.f_deleteRecords"
                            label="Delete Records"
                            color="green lighten-1"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!--Navigation Sections-->
              <v-tab-item>
                <v-card flat height="600">
                  <v-card-text>
                    <v-container fluid grid-list-sm>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-radio-group class="py-0 my-0">
                            <v-checkbox
                              v-for="check in navigationStore.navigation"
                              :key="check.id"
                              v-model="profile.navigation"
                              :label="check.d_description"
                              :value="check.id"
                              class="pr-4"
                              multiple
                              color="primary"
                            />
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded block color="primary" @click="saveProfile()">
            <v-icon small left>
              fal fa-arrow-alt-down </v-icon
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { clientID, clientSecret, uploadHeader } from "@/env";

export default {
  name: "ProfileEdit",
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      navigationStore: state => state.navigationStore,
      alertsStore: state => state.alertsStore
    })
  },
  props: {
    profile: {
      type: Object
    }
  },
  data() {
    return {
      rules: {
        customer: {
          d_companyName: [v => !!v || "required"],
          d_street1: [v => !!v || "required"],
          d_city: [v => !!v || "required"],
          d_state: [v => !!v || "required"],
          d_zip: [v => !!v || "required"],
          d_country: [v => !!v || "required"],
          d_billingCompanyName: [v => !!v || "required"],
          d_billingStreet1: [v => !!v || "required"],
          d_billingCity: [v => !!v || "required"],
          d_billingState: [v => !!v || "required"],
          d_billingZip: [v => !!v || "required"],
          d_billingCountry: [v => !!v || "required"]
        },
        user: {
          d_nameFirst: [v => !!v || "required"],
          d_nameLast: [v => !!v || "required"],
          d_avatarColor: [v => !!v || "required"],
          email: [v => !!v || "required"],
          f_access: [v => !!v || "required"]
        }
      },
      avatarColors: [
        {
          text: "Red",
          value: "red"
        },
        {
          text: "Pink",
          value: "pink"
        },
        {
          text: "Purple",
          value: "purple"
        },
        {
          text: "Dark Purple",
          value: "deep-purple"
        },
        {
          text: "Indigo",
          value: "indigo"
        },
        {
          text: "Blue",
          value: "blue"
        },
        {
          text: "Light BLue",
          value: "light-blue"
        },
        {
          text: "Cyan",
          value: "cyan"
        },
        {
          text: "Teal",
          value: "teal"
        },
        {
          text: "Green",
          value: "green"
        },
        {
          text: "Light Green",
          value: "light-green"
        },
        {
          text: "Lime",
          value: "lime"
        },
        {
          text: "Amber",
          value: "amber"
        },
        {
          text: "Orange",
          value: "orange"
        },
        {
          text: "Deep Orange",
          value: "deep-orange"
        }
      ],
      contactMethodTypes: [
        {
          id: 1,
          value: "Phone"
        },
        {
          id: 2,
          value: "Mobile"
        },
        {
          id: 3,
          value: "Email"
        },
        {
          id: 4,
          value: "Fax"
        }
      ],
      upload: false,
      files: []
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},

    close() {
      this.profile.open = false;
    },

    async saveProfile() {
      if (this.$refs.profile.validate()) {
        if (this.files) {
          this.profile.customer.d_taxCertificateFile = this.files.name;
        }
        /* If primary user, update customer */
        if (this.profile.user.f_primary === 1 && this.profile.mode === 1) {
          await this.$store.dispatch(
            "customersStore/updateProfile",
            this.profile.customer
          );
        }
        this.profile.user.navigation = this.profile.navigation;
        this.profile.user.j_contactMethods = this.profile.contactMethods;

        /* If adding a new user */
        if (this.profile.mode === 3) {
          await this.$store.dispatch("usersStore/create", this.profile.user);
        } else {
          await this.$store.dispatch("usersStore/update", this.profile.user);
        }

        this.addTaxFile();

        this.close();
      }
    },

    sameAddress() {
      if (
        this.profile.customer.f_billingSameAsCompany === 1 ||
        this.profile.customer.f_billingSameAsCompany === true
      ) {
        this.profile.customer.d_billingCompanyName = this.profile.customer.d_companyName;
        this.profile.customer.d_billingStreet1 = this.profile.customer.d_street1;
        this.profile.customer.d_billingStreet2 = this.profile.customer.d_street2;
        this.profile.customer.d_billingCity = this.profile.customer.d_city;
        this.profile.customer.d_billingState = this.profile.customer.d_state;
        this.profile.customer.d_billingZip = this.profile.customer.d_zip;
        this.profile.customer.d_billingCounty = this.profile.customer.d_county;
        this.profile.customer.d_billingCountry = this.profile.customer.d_country;
      } else {
        this.profile.customer.d_billingCompanyName = "";
        this.profile.customer.d_billingStreet1 = "";
        this.profile.customer.d_billingStreet2 = "";
        this.profile.customer.d_billingCity = "";
        this.profile.customer.d_billingState = "";
        this.profile.customer.d_billingZip = "";
        this.profile.customer.d_billingCounty = "";
        this.profile.customer.d_billingCountry = "";
      }
    },

    addContactMethod() {
      const data = {
        id: "",
        d_contactDetail: "",
        f_accounting: "",
        f_designApproval: "",
        f_shipConfirmation: "",
        f_type: ""
      };
      this.profile.contactMethods.push(data);
    },

    removeContactMethod(id) {
      this.profile.contactMethods.splice(id, 1);
    },

    async addTaxFile() {
      if (this.files) {
        const formData = new FormData();
        formData.append("file", this.files);
        formData.append("customerID", this.profile.customer.id);
        formData.append("taxYear", this.profile.customer.d_taxCertificateYear);
        formData.append(
          "taxNumber",
          this.profile.customer.d_taxCertificateNumber
        );
        this.upload = true;
        const response = await axios.post("/api/taxfile/submit", formData, {
          headers: uploadHeader()
        });
        this.upload = false;
        if (response.status === 200) {
          this.openSnackbar(1);
        } else {
          this.openSnackbar(2);
        }
      }
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "green lighten-1",
          text: "Artwork file has been successfully uploaded."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text:
            "There was an error uploading the file.  Try again and contact support if the issue continues."
        };
      }
      this.$store.commit("alertsStore/setAlert", data);
    }
  }
};
</script>

<style scoped>
button {
  outline: none;
}
</style>
